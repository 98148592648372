export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/api": [5],
		"/badPaymentLink": [6],
		"/changePassword": [7],
		"/connect": [8],
		"/enrich": [9],
		"/enrich/manual": [16],
		"/enrich/start": [17],
		"/enrich/upload": [18],
		"/enrich/[enrichId]/edit": [10,[2]],
		"/enrich/[enrichId]/export": [11,[2]],
		"/enrich/[enrichId]/field": [12,[2]],
		"/enrich/[enrichId]/result": [13,[2]],
		"/enrich/[enrichId]/settings": [14,[2]],
		"/enrich/[enrichId]/upgrade": [15,[2]],
		"/extension": [19],
		"/extension/new": [20],
		"/integrations": [21],
		"/integrations/clay": [22],
		"/integrations/make": [23],
		"/integrations/zapier": [24],
		"/invite": [25],
		"/login": [26],
		"/onboarding": [27],
		"/register": [28],
		"/resetPassword": [29],
		"/settings/billing": [30,[3]],
		"/settings/credits": [31,[3]],
		"/settings/invite": [32,[3]],
		"/settings/me": [33,[3]],
		"/settings/workspace": [34,[3]],
		"/signUp": [35],
		"/ssoError": [36],
		"/ssoUserExist": [37],
		"/workspaces": [38]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';